import "../css/main.css";

import "htmx.org";
import "alpinejs";

window.htmx = require("htmx.org");
window.Alpine = require("alpinejs").default;
window.dateFns = require("date-fns");

function closeDropdowns(e) {
  document.querySelectorAll(".dropdown").forEach((dropdown) => {
    if (!dropdown.contains(e.target)) {
      dropdown.open = false;
    }
  });
}
document.addEventListener("click", closeDropdowns);

function clearAlertQueryParam() {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has("alert")) {
    searchParams.delete("alert");
    const url =
      searchParams.size === 0
        ? window.location.pathname
        : `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState({}, document.title, url);
  }
}

function htmxInit() {
  // Always swap content after HTMX request regardless of response status.
  document.body.addEventListener("htmx:beforeSwap", function (evt) {
    evt.detail.shouldSwap = true;
    evt.detail.isError = false;
  });
}

function alpineInit() {
  // local-time directive converts a UTC timestamp to a human-readable local time.
  window.Alpine.directive("local-time", (el) => {
    const dateString = el.getAttribute("data-time");
    const isToday = dateFns.isToday(dateString);
    const isTomorrow = dateFns.isTomorrow(dateString);
    const time = dateFns.format(dateString, "h:mm a");

    let day = "";
    if (isToday) {
      day = "Today";
    } else if (isTomorrow) {
      day = "Tomorrow";
    } else {
      day = dateFns.format(dateString, "MMMM d");
    }
    el.innerText = `${day} at ${time}`;
    el.classList.remove("opacity-0");
  });

  // current-tooltip directive converts a UTC timestamp to local time and
  // sets tooltip content using the converted time.
  window.Alpine.directive("current-tooltip", (el) => {
    const dateString = el.getAttribute("data-time");
    const time = dateFns.format(dateString, "h:mm a");
    el.setAttribute("data-tip", `Current as of ${time}`);
  });

  // bets-countdown starts a countdown timer indicating when
  // the bets will become available for the event.
  window.Alpine.directive("bets-countdown", (el) => {
    const FIVE_MINUTES = 5 * 60; // 5 minutes in seconds

    const dateString = el.getAttribute("data-time");

    let updateInterval;

    function formatCountdown() {
      const diffSeconds = dateFns.differenceInSeconds(dateString, new Date());

      if (diffSeconds >= FIVE_MINUTES) {
        const remaining = dateFns.formatDistanceToNow(dateString, {});
        const label = `Drops in ${remaining}`;

        if (label !== el.innerText) {
          el.innerText = label;
        }
        return true;
      }

      if (diffSeconds > 0) {
        const remaining = dateFns.format(
          dateFns.addSeconds(new Date(0), diffSeconds),
          "m:ss",
        );
        const label = `Drops in ${remaining}`;
        el.innerText = label;
        return true;
      }

      clearInterval(updateInterval);

      el.innerText = "New bets incoming!";
      el.classList.add("badge-primary");
      el.classList.remove("badge-secondary");

      return false;
    }

    if (formatCountdown()) {
      updateInterval = setInterval(formatCountdown, 1000);
    }
    el.classList.remove("opacity-0");
  });

  window.Alpine.start();
}

clearAlertQueryParam();
htmxInit();
alpineInit();
